import * as React from "react"
import { Helmet } from "react-helmet"

//import Layout from "../kdp_theme/Layout"
import KdpBanner from "../components/banner"
import KdpHeader from "../components/headers/header"
import KdpFooter from "../components/footers/footer"

// markup
const NotFoundPage = () => {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Oups page introuvable | blicko.fr</title>
        <meta name="description" content="Oups page introuvable | blicko.fr" />
      </Helmet>
      <header>
        <KdpBanner />
        <KdpHeader />
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Oups !
                </h2>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                La page que vous cherchez ne semble plus disponible.
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                Nous sommes ravis de vous compter bientôt parmi nos partenaires.
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                <a href="/" className="text-blue-500">Revenir à l'accueil</a>.
                </p>
            </div>
            </div>
        </div>
        <KdpFooter />
      </header>
      <main>

      </main>
    </div>
  )
}

export default NotFoundPage
